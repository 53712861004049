import React, {
  useState,
  Fragment,
  useContext,
  useEffect,
  useRef,
} from "react";
import Header from "./Header";
import { useLocation, useNavigate } from "react-router-dom";
import AppContext from "../../context/AppProvider";
import {
  addPlanTypes,
  addSalesEntry,
  getPlanTypes,
  getSalesEntryDetail,
  updateSalesEntryDetail,
} from "../../config/api";
import PageLoading from "./PageLoading";
import moment from "moment";
import Loading from "./Loading";
import toast, { Toaster } from "react-hot-toast";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";

const AddSalesEntryForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    code: "",
    value: "",
    type: "",
    addType: "",
    startDate: null,
    endDate: null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [planTypes, setPlanTypes] = useState<string[]>([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isTypeLoading, setIsTypeLoading] = useState(false);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [formattedTitle, setFormattedTitle] = useState<string>("");
  const path = location.pathname.split("/").pop();
  const { setActiveIndex, salesId } = useContext(AppContext);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date: Dayjs | null, field: string) => {
    const formattedDate = date ? dayjs(date).format("MM/DD/YYYY") : null;
    setFormData((prevData) => ({
      ...prevData,
      [field]: formattedDate,
    }));
  };

  const validateForm = () => {
    if (!formData?.name || formData?.name.trim() === "") {
      toast.error("Name is required.");
      return false;
    }
    if (!formData?.code || formData?.code.trim() === "") {
      toast.error("Code is required.");
      return false;
    }
    if (!formData?.value || isNaN(Number(formData?.value))) {
      toast.error("Value must be a valid number.");
      return false;
    }
    if (!formData?.type || formData?.type.trim() === "") {
      toast.error("Type is required.");
      return false;
    }
    if (!formData?.startDate) {
      toast.error("Start date is required.");
      return false;
    }
    if (!formData?.endDate) {
      toast.error("End date is required.");
      return false;
    }

    if (formData?.endDate) {
      const startDate = new Date(formData.startDate);
      const endDate = new Date(formData.endDate);

      if (endDate <= startDate) {
        toast.error("End date must be greater than start date.");
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }
    if (formattedTitle === "Edit") {
      setIsButtonLoading(true);
      // const formatEndDate = formData?.endDate
      //   ? moment(formData?.endDate).format("MM/DD/YYYY")
      //   : "";
      // const formatStartDate = formData?.startDate
      //   ? moment(formData?.startDate).format("MM/DD/YYYY")
      //   : "";
      const parseValueToFloat = (value: string | undefined) => {
        return value && !isNaN(Number(value)) ? parseFloat(value) : 0.0;
      };

      try {
        const formatDate = (date: string | null) => {
          return date ? moment(date).format("MM/DD/YYYY") : null;
        };

        const data = {
          plan: {
            code: formData?.code,
            endDate: formatDate(formData.endDate),
            name: formData?.name,
            startDate: formatDate(formData.startDate),
            type: formData?.type,
            value: parseValueToFloat(formData?.value),
          },
          type: "Plan",
        };
        const response: any = await updateSalesEntryDetail(salesId, data);
        setIsButtonLoading(false);
        setActiveIndex(0);
        navigate("/sales");
      } catch (error: any) {
        toast.error(error.message);
        console.error("Error updating sales entry:", error);
        setIsButtonLoading(false);
      }
    } else if (formattedTitle === "View") {
      setFormattedTitle("Edit");
    } else {
      setIsButtonLoading(true);
      try {
        const parseValueToFloat = (value: string | undefined) => {
          return value ? parseFloat(value) : 0.0;
        };
        const data = {
          plan: {
            code: formData?.code.trim(),
            endDate: formData?.endDate,
            name: formData?.name.trim(),
            startDate: formData?.startDate,
            type: formData?.type.trim(),
            value: parseValueToFloat(formData?.value),
          },
          type: "Plan",
        };
        const response: any = await addSalesEntry(data);
        console.log("Sales Entry Updated:", response.data);
        setIsButtonLoading(false);
        setActiveIndex(0);
        navigate("/sales");
      } catch (error: any) {
        toast.error(error.message);
        console.error("Error updating sales entry:", error);
        setIsButtonLoading(false);
      }
    }
  };

  const handleBack = () => {
    navigate("/sales");
    setActiveIndex(0);
  };
  const firstPart = path?.split("-")[0];

  useEffect(() => {
    const pathName = firstPart
      ? firstPart.charAt(0).toUpperCase() + firstPart.slice(1)
      : "";
    setFormattedTitle(pathName);
  }, []);

  useEffect(() => {
    const fetchDetail = async () => {
      try {
        const response: any = await getSalesEntryDetail(salesId);
        const { name, code, value, type, addType, startDate, endDate } =
          response.data.plan;
        setFormData({
          name: name,
          code: code,
          value: value,
          type: type,
          addType: addType,
          startDate: startDate,
          endDate: endDate ? endDate : "",
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching sales entry detail:", error);
        setIsLoading(false);
      }
    };

    if ((formattedTitle === "View" || formattedTitle === "Edit") && salesId) {
      setIsLoading(true);
      fetchDetail();
    }
  }, [formattedTitle, salesId]);

  const fetchPlanType = async () => {
    try {
      const response: any = await getPlanTypes();
      setPlanTypes(response.data);
    } catch (error) {
      console.error("Error fetching plan types:", error);
    }
  };

  useEffect(() => {
    fetchPlanType();
  }, []);

  const filteredPlanTypes = planTypes?.filter((type) =>
    type.toLowerCase().includes(formData?.addType?.toLowerCase() || "")
  );

  const handleAddType = async () => {
    if (!formData?.addType) {
      toast.error("Please enter type.");
      return;
    }
    setIsTypeLoading(true);
    try {
      const data = {
        planType: formData?.addType.trim(),
      };
      const response: any = await addPlanTypes(data);
      setIsTypeLoading(false);
      fetchPlanType();
      setFormData((prevFormData) => ({
        ...prevFormData,
        addType: "",
        type: response?.type,
      }));
    } catch (error) {
      setIsTypeLoading(false);
      if (typeof error === "object" && error !== null && "message" in error) {
        console.error(
          "Error fetching sales entry detail:",
          (error as { message: string }).message
        );
        toast.error((error as { message: string }).message);
      } else {
        console.error("Unexpected error:", error);
        toast.error("An unexpected error occurred.");
      }
    }
  };

  return (
    <Fragment>
      <Header
        title={`${formattedTitle} Plan`}
        backButton={true}
        backHandle={handleBack}
        admin={true}
      />
      <Toaster />
      <form
        autoComplete="off"
        className="loaction-list-main add-plan-main"
        onSubmit={handleSubmit}
      >
        <div className="loaction-list-head">
          <h3>Plan Details</h3>
          <div className="">
            <button type="submit" className="add-service">
              {isButtonLoading ? (
                <Loading />
              ) : (
                `${formattedTitle === "View" ? "Edit" : "Save"}`
              )}
            </button>
          </div>
        </div>
        {isLoading ? (
          <PageLoading />
        ) : (
          <div
            className={`add-plan-detail ${
              formattedTitle === "Edit" || formattedTitle === "Add"
                ? "active"
                : ""
            }`}
          >
            <div className="input-group">
              <div className="input-field">
                <label>Name</label>
                <input
                  type="text"
                  name="name"
                  autoComplete="off"
                  placeholder="Total Base 5G Unlimited"
                  value={formData.name}
                  onChange={handleChange}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>Code</label>
                <input
                  type="text"
                  name="code"
                  autoComplete="off"
                  placeholder="TW40"
                  value={formData.code}
                  onChange={handleChange}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>Value</label>
                <input
                  type="text"
                  name="value"
                  placeholder="$40.00"
                  value={`$${formData.value}`}
                  onChange={handleChange}
                  onInput={(e) => {
                    const target = e.target as HTMLInputElement;
                    target.value = target.value.replace(/[^0-9]/g, "");
                  }}
                  disabled={formattedTitle === "View"}
                />
              </div>
              <div className="input-field">
                <label>Type</label>
                <button
                  type="button"
                  className={`${
                    formattedTitle === "Edit" || formattedTitle === "Add"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => {
                    if (formattedTitle === "View") return;
                    setDropDownOpen(!dropDownOpen);
                  }}
                >
                  {formData.type || "Select"}
                  {formattedTitle !== "View" && (
                    <img src="/images/angle_down_grey.svg" alt="Toggle" />
                  )}
                </button>
                <div
                  className={`carrier-search-main ${
                    dropDownOpen ? "active" : ""
                  }`}
                  ref={dropdownRef}
                >
                  <div className="search-bar">
                    <input
                      type="search"
                      placeholder="Enter New Type"
                      name="addType"
                      value={formData?.addType || ""}
                      onChange={handleChange}
                      disabled={formattedTitle === "View"}
                    />

                    <button type="button" name="addBtn" onClick={handleAddType}>
                      {" "}
                      {isTypeLoading ? <Loading /> : "+Add"}
                    </button>
                  </div>
                  <ul>
                    {filteredPlanTypes.length > 0 ? (
                      filteredPlanTypes.map((type, index) => (
                        <li
                          key={index}
                          onClick={() => {
                            setFormData({ ...formData, type: type });
                            setDropDownOpen(false);
                          }}
                        >
                          {type}
                        </li>
                      ))
                    ) : (
                      <li>No results found</li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="input-field datepicker-wrapper">
                <label>Start Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={
                      formData.startDate ? dayjs(formData.startDate) : null
                    }
                    onChange={(date) => handleDateChange(date, "startDate")}
                    disabled={formattedTitle === "View"}
                  />
                </LocalizationProvider>
              </div>

              <div className="input-field datepicker-wrapper">
                <label>End Date</label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={formData.endDate ? dayjs(formData.endDate) : null}
                    onChange={(date) => handleDateChange(date, "endDate")}
                    disabled={formattedTitle === "View"}
                  />
                </LocalizationProvider>
              </div>
            </div>
          </div>
        )}
      </form>
    </Fragment>
  );
};

export default AddSalesEntryForm;
